<template>
  <div class="row parent">
    <div class="flex md6 lg6">
        <va-card :bordered="false" style="margin-top: 20px;">
          <va-card-title>You must provide a valid event ID.</va-card-title>
          <va-card-content>Contact AWS Serverless DA for assistance.</va-card-content>
        </va-card>
    </div>
  </div>
</template>

<script>
/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */
'use strict'
export default {
  name: 'Auth',
  data() {
    return {

    }
  },
  async mounted () {

  },
  methods: {

  }
}
</script>
<style>
.parent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>