<template>
  <!-- Only show if logged out -->
  <div v-if="authState != 'signedin'">
    <Authentication />
  </div>
  <div v-else>

    <div v-if="this.config"><Wrapper :config="config"/></div>
    <div v-else><NoEventID /></div>
  </div>
</template>

<script>
/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'

import axios from 'axios'
import Auth from '@aws-amplify/auth'

import Wrapper from "@/Wrapper"
import NoEventID from "@/components/NoEventID"
import Authentication from "./components/Auth"

export default {
  name: "App",
  components: {
    Wrapper,
    NoEventID,
    Authentication
  },
  data () {
    return {
      authState: undefined,
      config: undefined,
      eventId: undefined
    }
  },
  async mounted () {
    this.eventId = window.location.pathname.replace('/','')
    console.log('App.vue mounted: ', this.eventId )

    let that = this

    // Login/logout events
    this.emitter.on("authStateChanged", async function(detail) {
      console.log("mounted::authStateChanged: ", detail)
      if (detail.loggedIn) {
        that.authState = "signedin"
        that.user = detail.authData

        // Set store config
        await that.getConfig()
      } else {
        that.authState = ""
      }
      console.log("mounted::authStateChanged: ", that.authState)
    })
  },
  methods: {
    async getConfig () {
      // Refresh token - get config
      const session = await Auth.currentSession()
      const jwtToken = session.getAccessToken().jwtToken

      // Sensitive info - only log in development
      if (process.env.NODE_ENV=== 'development') console.log('token: ', jwtToken)
      try {
        const qrURL = `${this.$ConfigEndpoint}?eventId=${this.eventId}`
        console.log('Querying: ', qrURL)
        const { data } = await axios.get(qrURL, { headers: { Authorization: 'Bearer ' + jwtToken }})
        this.config = data.Item


      } catch (err) {
        console.log("Cannot load config: ", err)
      }
    }
  }
}
</script>
