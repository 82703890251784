<template>
  <!-- Store open -->
  <div class="div tokenContainer" style="padding-top:0px;">
    <!-- Tokens available -->
    
    <div v-if="Object.keys(waitTimes).length>0" >
      <h2>Average wait times:</h2>
      <ul>
          <li v-for="(avg) in waitTimes" :key="avg.drink">
            {{avg.drink}}<br><span v-if="(Math.floor(avg.duration / 60 != '0'))"> {{ Math.floor(avg.duration / 60)}}m</span> {{Math.round(avg.duration - (Math.floor(avg.duration / 60)) * 60) }}s
          </li>
      </ul>
    </div>
   
  </div>
</template>

<script>
/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'


import Auth from '@aws-amplify/auth'




// Timer interval to calculate remaining period
const REFRESH_MS = 1000

export default {
  name: 'QRcode',

  data () {
    return {
      eventId: undefined,
      waitTimes: {},

      region: 'uswest2'
    }
  },
  components: {

  },
  mounted () {
    console.log('QR code mounted: ', window.location.pathname.replace('/',''))
    const that = this
    this.eventId = window.location.pathname.replace('/','')
  

    this.emitter.on('authStateChanged', (authData) => {
      console.log('QR::bus:', authData)
      if (authData.loggedIn) {
        this.isActive = true
        this.refresh()
        this.intervalRef = setInterval(this.refresh, REFRESH_MS)
        // user signed in
        Auth.currentCredentials().then((info) => {
          console.log('ID: ', info)
        })
      } else {
        // user signed out
        clearInterval(this.intervalRef)
      }
    }),
    // Incoming event - change in token quantity
    this.emitter.on('message', (payloadEnvelope) => {
      console.log(' event:', payloadEnvelope)
      // Change in token quantity
      if (payloadEnvelope['type'] =='OrderManager.DrinkAverageTime') {
          that.waitTimes[payloadEnvelope.detail.drink] = payloadEnvelope.detail
      }
    })
  },
  methods: {
      

  }
}
</script>

<style scoped>
div {
  align-items: center;
  padding: 4px;
  justify-content: center;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.center {
  text-align:center;
}
.timerContainer {
  display:flex;
  flex-flow: column nowrap;
  align-items:center;
  justify-content:center;
}
.tokenContainer {
  padding-top:20px;
  padding-bottom:20px;
  background:white;
  border-radius:5px;
}
h2{text-align: left; padding-left:20px; text-decoration: underline; padding-bottom: 10px;;}

li{
  list-style: none;
  text-align: left;
  font-size: 12px;;
}

ul {
  column-count: 3;
}
</style>
