<template>
  <va-card :bordered="false" style="height:100% !important;">
    <va-card-title><h1>Preparing</h1></va-card-title>
    <va-card-content>
      <div class="twoColContainer">
        <va-list-item style="width: 49%;" v-for="(order, index) in previews" :key="order.orderId">
          <transition name="fade" v-if="index <= 21">
            <div v-if="order.orderNumber">
              <div class="row">
                <div class=" md4">
                  <span style="font-size:52px; color:#08c18a; padding-right: 16px;">{{
                    order.orderNumber
                  }}</span>
                </div>
                <div class="md4 offset--md4" style="text-align:left">
                  <strong  style="font-size:30px;"
                    >{{ order.orderItemName }}
                  </strong>
                  <br />
                  <p class="timer">{{ order.displayAge }}</p>
                </div>
              </div>
              <va-list-separator  spaced class="small" />
            </div>
          </transition>
        </va-list-item>
      </div><!-- twoColContainer -->
    </va-card-content>
  </va-card>
</template>

<script>
/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

const AWSsdk = require('aws-sdk');
'use strict'

import axios from "axios"
import Auth from "@aws-amplify/auth"

export default {
  name: "UpcomingOrders",
  data() {
    return {
      interval: undefined,
      eventId: undefined,
      orders: [],
    }
  },
  computed: {
    // Enables v-for loop on filtered list of orders
    previews: function() {
      console.log('previews:', this.orders.filter((order) => (order.state === "CREATED") & (order.orderNumber != undefined) ))
      return this.orders.filter((order) => (order.state === "CREATED") & (order.orderNumber != undefined) )
    },
  },
  async mounted() {
    let that = this
    this.interval = setInterval(() => that.refreshData(), 1000)
    this.eventId = window.location.pathname.replace('/','')

    this.emitter.on("message", function(obj) {
      console.log("UpcomingOrders::mounted: ", obj)

      if (obj.type.includes('OrderManager') && obj.type.includes('COMPLETED')) {
        for (let v of that.orders) {
          if (v.orderId == obj.detail.orderId) {
            v.state = "COMPLETED";
            that.emitter.emit("COMPLETED", v)
          }
        }
        return
      }

      if (obj.type.includes('OrderManager') && obj.type.includes('CANCELLED')) {
        for (let v of that.orders) {
          if (v.orderId == obj.detail.orderId) {
            v.state = "CANCELLED"
            delete (that.orders, v)
          }
        }
        return
      }

      if (obj.type == "OrderProcessor.OrderTimeOut") {
        for (let v of that.orders) {
          if (v.orderId == obj.detail.orderId) {
            v.state = "CANCELLED"
            delete (that.orders, v)
          }
        }
        return
      }

      if (obj.type == "OrderManager.WaitingCompletion") {
        let exists = false
        // Check for duplicate messages
        for (let v of that.orders) {
          try {
            if (v.orderId == obj.detail.orderId) {
              exists = true
            }
          } catch (err) {
            console.log('Unknown object type: ', v, err)
          }
        }
        // Add order
        if (!exists) {
          that.orders.push({
            orderId: obj.detail.orderId,
            orderNumber: obj.detail.orderNumber,
            customerName: "Name",
            orderItemName: obj.detail.drinkOrder.drink,
            icon: obj.detail.drinkOrder.icon,
            modifiers: obj.detail.drinkOrder.modifiers,
            startTime: obj.detail.TS, //Date.now() - 10000,
            state: "CREATED",
            age: 0
          })
        }
        console.log('Orders: ', that.orders)
      }
    })
    await this.loadOrders()
  },
  methods: {
    async loadOrders() {
      console.log("loadOrders started")
      console.log('this.$orderManagerEndpoint = ', this.$orderManagerEndpoint)

      // Refresh token
      const session = await Auth.currentSession()
      const jwtToken = session.getAccessToken().jwtToken

      try {
        const url = `${this.$orderManagerEndpoint}/orders?state=CREATED&eventId=${this.eventId}`
        console.log('loadOrders: ', url)
        const { data } = await axios.get(url, {
          headers: { Authorization: "Bearer " + jwtToken }
        })
        console.log("orders", data)

        data.map((order) => {
          order = AWSsdk.DynamoDB.Converter.unmarshall(order)
          if(order.drinkOrder){
            order.drinkOrder =  JSON.parse(order.drinkOrder)
          }
          console.log("Order:", order);

          this.orders.push({
            orderId: order.SK,
            orderNumber: order.orderNumber,
            customerName: "Name",
            orderItemName: order.drinkOrder?.drink,
            icon: order.drinkOrder?.icon,
            modifiers: order.drinkOrder?.modifiers,
            startTime: order.TS, //Date.now() - 10000,
            state: "CREATED",
            age: 0
          })
          console.log("ORDERS: ", this.orders)
        })
      } catch (err) {
        console.log("Cannot load orders: ", err)
      }
    },
    // Convert age in seconds to string with mins/secs
    getMinsSecs(age) {
      try {
        // Minutes and seconds
        const mins = ~~(age / 60)
        const secs = ~~age % 60

        // Only return mins if more than a minute
        let min = ""
        if (mins > 0) min = mins + "m "
        return min + secs + "s"
      } catch (err) {
        console.log("getMinsSecs error", err)
        return ""
      }
    },
    refreshData() {
      // Update ticket age
      for (let i = 0; i < this.orders.length; i++) {
        this.orders[i].age = parseInt ((Date.now() - this.orders[i].startTime) / 1000 )
        this.orders[i].displayAge = this.getMinsSecs(this.orders[i].age)
      }
    }
  }
}
</script>

<style>
.va-list-item-label {
  font-size: 2em !important;
}
.va-list-item-label.smaller {
  font-size: 1em !important;
}

.va-avatar img,
.va-avatar svg {
  width: inherit;
  top: -3px;
  position: relative;
}

.va-list-item-section {
  display: inline-block !important;
}

.va-list-item-section > * {
  text-align: left !important;
}

h1 {
  font-size: 40px !important;
}

.twoColContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.va-list-separator.va-list-separator--offset.va-list-separator--spaced.small{
  margin:0.4rem 0;
}

</style>